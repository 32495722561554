
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import { MenuComponent } from "@/assets/ts/components";
import { ApiBase, ApiGroup } from "@/core/api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";
import { setModuleBCN } from "@/core/directive/function/common";

export default defineComponent({
  name: "group-information",
  components: {},
  setup() {
    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const deleteButton = ref<HTMLElement | null>(null);
    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();
    const { t } = useI18n();
    const groupInfo = ref({
      name: "",
      email: "",
      address: "",
      website: "",
    });

    const formData = ref({
      id: route.params.id,
      status: 10,
      name: "",
      short_key: "",
      email: "",
      website: "",
      telephone: "",
      telephone2: "",
      fax: "",
      address: "",
      zip_code: "",
      city: "",
      country_iso_2: "",
      overall_notes: "",
      __show: {
        created_uid: "",
        updated_uid: "",
      },
    });

    const countryOptions = ref([]);
    const rules = ref({
      name: [
        {
          required: true,
          message: "Company Name is required",
          trigger: "change",
        },
      ],
      short_key: [
        {
          required: true,
          message: "Company Short-Key is required",
          trigger: "change",
        },
      ],
      telephone: [
        {
          required: true,
          message: "Telephone is required",
          trigger: "change",
        },
      ],
      address: [
        {
          required: true,
          message: "Address is required",
          trigger: "change",
        },
      ],
      zip_code: [
        {
          required: true,
          message: "Zip Code is required",
          trigger: "change",
        },
      ],
      city: [
        {
          required: true,
          message: "City is required",
          trigger: "change",
        },
      ],
      country_iso_2: [
        {
          required: true,
          message: "Country is required",
          trigger: "change",
        },
      ],
      merchant_id: [
        {
          required: true,
          message: "Parent Merchant is required",
          trigger: "change",
        },
      ],
    });

    const getGroupData = () => {
      loading.value = true;
      ApiGroup.getGroupInfo({ id: route.params.id })
        .then(({ data }) => {
          loading.value = false;
          if (data.code == 0) {
            formData.value = data.data;
            groupInfo.value.name = data.data.name;
            groupInfo.value.email = data.data.email;
            groupInfo.value.address = data.data.address;
            groupInfo.value.website = data.data.website;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const deleteGroup = () => {
      Swal.fire({
        text: "Are you sure you would like to deactivate your Group?",
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          if (deleteButton.value) {
            deleteButton.value.setAttribute("data-kt-indicator", "on");
          }
          loading.value = true;
          ApiGroup.deleteGroup({ id: route.params.id })
            .then(({ data }) => {
              loading.value = false;
              deleteButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                Swal.fire({
                  text: "Your Group has been deactivated!",
                  icon: "success",
                  buttonsStyling: false,
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                }).then(() => {
                  router.push({ name: "groups" });
                });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiGroup.updateGroup(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                groupInfo.value.name = formData.value.name;
                groupInfo.value.email = formData.value.email;
                groupInfo.value.address = formData.value.address;
                groupInfo.value.website = formData.value.website;
                showFormSubmitSuccessMsg();
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getCountryOptions = () => {
      ApiBase.getCountryData()
        .then(({ data }) => {
          if (data.code == 0) {
            countryOptions.value = data.data;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    onMounted(() => {
      MenuComponent.reinitialization();
      setModuleBCN(t, route, router);
      getGroupData();
      getCountryOptions();
    });

    return {
      loading,
      groupInfo,
      formData,
      rules,
      formRef,
      submitButton,
      deleteButton,
      t,
      submit,
      countryOptions,
      getGroupData,
      deleteGroup,
    };
  },
});
